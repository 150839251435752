<template>
  <div class="GoldBox">
    <slot>
      <div class="GoldBox__Block">
        内容
      </div>
    </slot>
  </div>
</template>
<script>
export default {
  name: 'GoldBox'
}
</script>
<style lang="scss" scoped>
.GoldBox {
  @at-root {
    & {
      background: url(#{$img-path}gold-box/bg_summon.png) no-repeat center 90px,
                  url(#{$img-path}gold-box/bg_middle.png) repeat-y center 0;
      width: 630px;
      position: relative;
      padding: 1px;
      &::before,
      &::after {
        content: '';
        display: block;
        height: 162px;
        position: absolute;
        width: 100%;
        left: 0;
        right: 0;
      }
      &::before {
        background: url(#{$img-path}gold-box/bg_top.png) no-repeat center 0;
        top: -162px;
      }
      &::after {
        background: url(#{$img-path}gold-box/bg_bottom.png) no-repeat center bottom;
        bottom: -162px;
      }
      .GoldBox__Block {
        border-style: solid;
        text-shadow: $ts-base;
        background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(38,34,19,0.01) 1%,rgba(38,34,19,0.02) 2%,rgba(38,34,19,0.07) 4%,rgba(38,34,19,0.14) 5%,rgba(38,34,19,0.23) 6%,rgba(38,34,19,0.57) 8%,rgba(38,34,19,0.68) 10%,rgba(38,34,19,0.75) 12%,rgba(38,34,19,0.75) 88%,rgba(38,34,19,0.65) 90%,rgba(38,34,19,0.57) 92%,rgba(38,34,19,0.27) 94%,rgba(38,34,19,0.19) 95%,rgba(38,34,19,0.07) 96%,rgba(38,34,19,0) 98%,rgba(38,34,19,0) 99%,rgba(255,255,255,0) 100%);
        width: 577px;
        margin: 0 auto;
        padding: 40px 0;
        font-size: $fs-3large;
        position: relative;
        &::before,
        &::after {
          position: absolute;
          content: '';
          display: block;
          height: 3px;
          left: 0;
          right: 0;
          background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 224, 0.01) 1%, rgba(255, 255, 224, 0.01) 2%, rgba(255, 255, 225, 0.09) 4%, rgba(255, 255, 226, 0.24) 6%, rgba(255, 255, 227, 0.61) 8%, rgba(255, 255, 228, 0.73) 10%, rgba(255, 255, 229, 0.8) 12%, rgba(255, 255, 252, 0.8) 88%, rgba(255, 255, 253, 0.72) 90%, rgba(255, 255, 253, 0.61) 92%, rgba(255, 255, 254, 0.26) 94%, rgba(255, 255, 254, 0.12) 95%, rgba(255, 255, 255, 0.02) 97%, rgba(255, 255, 255, 0.01) 99%, rgba(255, 255, 255, 0) 100%);
        }
        &::before {
          top: -3px;
        }
        &::after {
          bottom: -3px;
        }
      }
    }
  }
}
</style>
