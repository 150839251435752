<template>
  <div class="ResultList">
    <gold-box class="ResultList__Main">
      <h1 class="ResultList__Title ResultListTitle">
        <img
          :src="require(`@images/result/${locale}/title.png`)"
          :alt="$t('result.001')"
        >
      </h1>
      <paging class="ResultList__Paging -top" />
      <table class="ResultList__Table ResultListTable">
        <thead>
          <tr>
            <th class="ResultListTable__Th -rank" v-text="$t('result.007')" />
            <th class="ResultListTable__Th -name" v-text="$t('result.008')" />
            <th class="ResultListTable__Th -votes" v-text="$t('result.009')" />
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, i) in rankingList"
            :key="i"
            class="ResultListTable__Tr"
          >
            <td class="ResultListTable__Td -rank" v-text="item.rank" />
            <td class="ResultListTable__Td -name">
              <span class="ResultListTable__Nickname" v-text="$t(`nickname.${item.heroId}`)" /><br>
              <span class="ResultListTable__Name" v-text="$t(`character.${item.heroId}`)" />
            </td>
            <td class="ResultListTable__Td -votes" v-text="delimit(item.voteCount)" />
          </tr>
        </tbody>
      </table>
    </gold-box>
    <paging class="ResultList__Paging -bottom" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { delimit } from '@/libraries/util'
import LocaleMixin from '@/mixins/locale'
import LoaderMixin from '@/mixins/loader'
import GoldBox from '@/components/global/gold-box.vue'
import Paging from '@/components/result/paging.vue'

export default {
  name: 'ResultList',
  mixins: [LocaleMixin, LoaderMixin],
  components: {
    GoldBox,
    Paging
  },
  computed: {
    ...mapGetters('result', ['getRanking']),
    rankingList () {
      return +this.$route.params.page === 1 ? this.getRanking.filter(r => +r.rank < 101) : this.getRanking.filter(r => +r.rank > 100)
    }
  },
  methods: {
    delimit (count) {
      return delimit(count, this.locale)
    }
  }
}
</script>

<style lang="scss" scoped>
.ResultList {
  @at-root {
    & {
      margin-bottom: 34px;
    }
    // NOTE: override generic component
    & .ResultList__Main {
      margin: 180px auto;
      background: url(#{$img-path}gold-box/bg_middle.png) repeat-y center top;
    }
    .ResultList__Title {
      margin-top: -220px;
    }
    // NOTE: override generic component
    .ResultList__Table {
      margin: 33px auto 0;
    }
    .ResultList__Paging {
      position: relative;
      &.-top {
        margin: 20px 45px 40px;
      }
      &.-bottom {
        margin: 0 20px 40px;
      }
    }
  }
}

.ResultListTitle {
  @at-root {
    & {
      position: relative;
      width: 628px;
      height: 207px;
      padding: 115px 0 15px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: url(#{$img-path}result/bg_title.png) no-repeat 0 0;
      text-align: center;
    }
  }
}

.ResultListTable {
  @at-root {
    & {
      position: relative;
      width: 577px;
      border-style: solid;
      background: linear-gradient(
        to right,
        rgba(255,255,255,0) 0%,
        rgba(38,34,19,0.01) 1%,
        rgba(38,34,19,0.02) 2%,
        rgba(38,34,19,0.07) 4%,
        rgba(38,34,19,0.14) 5%,
        rgba(38,34,19,0.23) 6%,
        rgba(38,34,19,0.57) 8%,
        rgba(38,34,19,0.68) 10%,
        rgba(38,34,19,0.75) 12%,
        rgba(38,34,19,0.75) 88%,
        rgba(38,34,19,0.65) 90%,
        rgba(38,34,19,0.57) 92%,
        rgba(38,34,19,0.27) 94%,
        rgba(38,34,19,0.19) 95%,
        rgba(38,34,19,0.07) 96%,
        rgba(38,34,19,0) 98%,
        rgba(38,34,19,0) 99%,
        rgba(255,255,255,0) 100%
      );
    }
    &::before,
    &::after {
      position: absolute;
      content: '';
      display: block;
      height: 3px;
      left: 0;
      right: 0;
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 224, 0.01) 1%,
        rgba(255, 255, 224, 0.01) 2%,
        rgba(255, 255, 225, 0.09) 4%,
        rgba(255, 255, 226, 0.24) 6%,
        rgba(255, 255, 227, 0.61) 8%,
        rgba(255, 255, 228, 0.73) 10%,
        rgba(255, 255, 229, 0.8) 12%,
        rgba(255, 255, 252, 0.8) 88%,
        rgba(255, 255, 253, 0.72) 90%,
        rgba(255, 255, 253, 0.61) 92%,
        rgba(255, 255, 254, 0.26) 94%,
        rgba(255, 255, 254, 0.12) 95%,
        rgba(255, 255, 255, 0.02) 97%,
        rgba(255, 255, 255, 0.01) 99%,
        rgba(255, 255, 255, 0) 100%
      );
    }
    &::before {
      top: -3px;
    }
    &::after {
      bottom: -3px;
    }
    .ResultListTable__Tr {
      position: relative;
      &::after {
        content: '';
        position: absolute;
        display: block;
        left: 0;
        width: 100%;
        height: 1px;
        background: url(#{$img-path}result/line.png) no-repeat center 0;
      }
    }
    .ResultListTable__Th,
    .ResultListTable__Td {
      padding-top: 20px;
      padding-bottom: 20px;
      vertical-align: middle;
    }
    .ResultListTable__Th {
      font-weight: normal;
      font-size: $fs-large;
      &.-rank {
        width: 9em;
        text-align: center;
      }
      &.-name {
        text-align: left;
        line-height: 1.3;
      }
      &.-votes {
        text-align: right;
        padding-right: 30px;
      }
    }
    .ResultListTable__Td {
      &.-rank {
        font-size: $fs-xlarge;
        text-align: center;
      }
      &.-name {
        text-align: left;
        line-height: 1.3;
      }
      &.-votes {
        padding-right: 30px;
        font-size: $fs-xlarge;
        text-align: right;
      }
    }
    .ResultListTable__Nickname {
      font-size: $fs-large;
    }
    .ResultListTable__Name {
      font-size: $fs-2xlarge;
    }
  }
}
</style>
