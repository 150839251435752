<template>
  <component
    :is="tag"
    @click.prevent="handleClick"
    class="ButtonBack"
  >
    <img :src="require(`@images/common/${locale}/btn_back.png`)" :alt="$t('common.003')">
  </component>
</template>

<script>
import LocaleMixin from '@/mixins/locale'

export default {
  name: 'ButtonBack',
  mixins: [LocaleMixin],
  props: {
    type: {
      type: String,
      default: null
    },
    tag: {
      type: String,
      default: 'button'
    }
  },

  methods: {
    handleClick () {
      this.$emit('click')
    }
  }
}
</script>

<style lang="scss" scoped>
.ButtonBack {
  @at-root {
    & {
      @include tap-event();
      display: inline-block;
    }
  }
}
</style>
