<template>
<div class="Paging">
  <btn-back class="Paging__BackButton" @click="$router.push({name: 'Top', params: {lang: locale}})" />
  <div class="Paging__Window">
    <ul class="Paging__List">
      <li class="Paging__ListItem" :class="{'-active': +$route.params.page === 1}">
        <router-link :to="{name: 'ResultList', params: {lang: locale, page: '1'}}" v-text="$t('result.005')" />
      </li>
      <li class="Paging__ListItem" :class="{'-active': +$route.params.page === 2}">
        <router-link :to="{name: 'ResultList', params: {lang: locale, page: '2'}}" v-text="$t('result.006')" />
      </li>
    </ul>
  </div>
</div>
</template>

<script>
import LocaleMixin from '@/mixins/locale'
import BtnBack from '@/components/global/button-back.vue'

export default {
  mixins: [LocaleMixin],
  components: {
    BtnBack
  }
}
</script>

<style lang="scss" scoped>
.Paging {
  @at-root {
    & {
      display: flex;
      justify-content: center;
    }
    .Paging__Window {
      flex: 1 0 0;
      height: 109px;
      border-width: 0;
      border-image: url(#{$img-path}result/bg_result-ranking.png) 0 fill stretch;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: $fs-3large;
      margin-left: 4px;
    }
    .Paging__List {
      position: relative;
      text-align: center;
    }
    .Paging__ListItem {
      text-align: center;
      width: 190px;
      display: inline-block;
      white-space: nowrap;
      a {
        text-decoration: underline;
      }
      &.-active a {
        text-decoration: none;
        cursor: default;
      }
    }
  }
}
</style>
